<template>
    <div class="wrap-dashboard">
        <div class="main-content">
            <div class="wrap-content">
                <div class="title-page">Detail Withdrawal</div>
                <div class="filter">
                    <label class="label-input"><b>Withdrwal</b></label>
                    <div class="wrap-input">
                        <label class="label-input">HIN</label>
                        <span>:</span>
                        <span>{{ dataDetail.owner }}</span>
                    </div>
                    <div class="wrap-input">
                        <label class="label-input">Total Item</label>
                        <span>:</span>
                        <span>{{ dataDetail.total_item }}</span>
                    </div>
                    <div class="wrap-input">
                        <label class="label-input">Total Weight</label>
                        <span>:</span>
                        <span>{{ dataDetail.total_weight }} gr</span>
                    </div>
                    <div class="wrap-input">
                        <label class="label-input">Status</label>
                        <span>:</span>
                        <span v-show="dataDetail.status == '001'" class="status-withdrawal request">Admin KMI Request Withdrawal</span>
                        <span v-show="dataDetail.status == '002'" class="status-withdrawal kbi">Admin KBI Apporve</span>
                        <span v-show="dataDetail.status == '003'" class="status-withdrawal abx">Operator KMI Confirmation (Stored)</span>
                    </div>
                </div>
                <div class="wrap-metal mt-4">
                    <h6>Detail Metal</h6>
                    <div class="content">
                        <table>
                            <thead>
                                <tr id="top">
                                    <th scope="col" rowspan="2" style="width: 70px">No</th>
                                    <th scope="col">Serial Number</th>
                                    <th scope="col">Year</th>
                                    <th scope="col" rowspan="2" style="width: 120px" v-if="typeWithdraw == 'onexchange'">Contract</th>
                                    <th scope="col" rowspan="2">Weight (gr)</th>
                                    <!-- <th scope="col" rowspan="2">Dimension (mm)</th> -->
                                    <th scope="col" rowspan="2">Fineness</th>
                                    <th scope="col" rowspan="2">Hallmark</th>
                                    <!-- <th scope="col" rowspan="2">Status</th>
                                    <th scope="col" rowspan="2" v-show="role != 6" v-if="typeWithdraw == 'onexchange'">Action</th> -->
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(data, i) in dataMetal" :key="i">
                                    <td data-label="No">{{ i + 1 }}</td>
                                    <td data-label="Serial Number">{{ data.serial_number }}</td>
                                    <td data-label="Amount">{{ data.year }}</td>
                                    <td data-label="Depasite Date" v-if="typeWithdraw == 'onexchange'">
                                        <span v-show="data.contract_id == 1">100 Gram Non Pool</span>
                                        <span v-show="data.contract_id == 3">1 KG Non Pool</span>
                                        <span v-show="data.contract_id == 7">0.01 Gram Pool</span>
                                        <span v-show="data.contract_id == 5">0.01 Gram Pool</span>
                                    </td>
                                    <td data-label="Weight">{{ data.weight }}</td>
                                    <!-- <td data-label="Dimension">{{ data.dimension }}</td> -->
                                    <td data-label="Fineness">{{ data.fineness }}</td>
                                    <td data-label="Hallmark">{{ data.hallmark }}</td>
                                    <!-- <td data-label="Status">
                                        <div v-if="typeWithdraw == 'onexchange'">
                                            <span v-show="data.status == '000'" class="status-processed">Process</span>
                                            <span v-show="data.status == '001'" class="status-allocated">Allocated</span>
                                            <span v-show="data.status == '002'" class="status-rejected">Re-Process</span>
                                        </div>
                                        <div v-else>
                                            <span v-show="data.status == '001'" class="status-withdrawal request">Processing</span>
                                            <span v-show="data.status == '002'" class="status-withdrawal kbi">Clearing Approve</span>
                                            <span v-show="data.status == '003'" class="status-withdrawal abx">KMI Approve</span>
                                            <span v-show="data.status == '004'" class="status-withdrawal operator">Client add detail withdrawal</span>
                                            <span v-show="data.status == '005'" class="status-withdrawal kbi">Operator Confirmation</span>
                                            <span v-show="data.status == '006'" class="status-withdrawal shipping">Clearing Confirmation</span>
                                            <span v-show="data.status == '007'" class="status-allocated">Allocated</span>
                                            <span v-show="data.status == '008'" class="status-withdrawal kprk">Client Withdrawal</span>
                                        </div>
                                    </td> -->

                                    <!-- <td data-label="Action" v-show="role != 6" v-if="typeWithdraw == 'onexchange'">
                                        <div v-show="role == 5 && withdrawal_status == '002' && data.status == '000'">
                                            <span class="border rounded border-success p-2 mr-2" @click="showModalOperator(data._id, 1)">
                                                <i class="fa fa-check"></i>
                                            </span>
                                            <span class="border rounded border-danger p-2" @click="showModalOperator(data._id, 2)">
                                                <i class="fa fa-times"></i>
                                            </span>
                                        </div>
                                    </td> -->
                                </tr>
                            </tbody>
                        </table>
                        <!-- ======= Approve KBI OnExchange======= -->
                        <div v-if="typeWithdraw == 'onexchange'">
                            <div v-show="role == 1 && withdrawal_status == '001'">
                                <b-button class="ml-2" variant="success" @click="processWithdrawl">
                                    <span v-show="!loadingKbi">Process</span>
                                    <b-spinner label="Loading..." variant="light" small v-show="loadingKbi"></b-spinner>
                                </b-button>
                            </div>
                            <div v-show="role == 1 && withdrawal_status == '004'">
                                <b-button class="ml-2" variant="success" @click="apprveKBI">
                                    <span v-show="!loadingKbi">Process</span>
                                    <b-spinner label="Loading..." variant="light" small v-show="loadingKbi"></b-spinner>
                                </b-button>
                            </div>
                        </div>
                        <div v-else>
                            <div v-show="role == 1 && withdrawal_status == '001'">
                                <b-button class="ml-2" variant="success" @click="offExchangeApprove">
                                    <span v-show="!loadingKbi">Process</span>
                                    <b-spinner label="Loading..." variant="light" small v-show="loadingKbi"></b-spinner>
                                </b-button>
                            </div>
                            <div v-show="role == 1 && withdrawal_status == '005'">
                                <b-button class="ml-2" variant="success" @click="offExchangeConfirmation">
                                    <span v-show="!loadingKbi">Process</span>
                                    <b-spinner label="Loading..." variant="light" small v-show="loadingKbi"></b-spinner>
                                </b-button>
                            </div>
                        </div>

                        <!-- ======== Modal ABX ======== -->

                        <b-modal ref="my-modal" hide-footer title="Input Detail Metal">
                            <div class="d-block text-center">
                                <form action="">
                                    <div class="form-group">
                                        <label for="" class="text-left">Allocated</label>
                                        <input type="text" v-model="form.allocated" />
                                    </div>
                                    <div class="form-group">
                                        <label for="" class="text-left">Hub</label>
                                        <input type="text" v-model="form.hub" />
                                    </div>
                                    <div class="form-group">
                                        <label class="text-left">Dimension (mm<sup>3</sup>) </label>
                                        <input type="text" v-model="form.dimension" />
                                    </div>
                                    <div class="form-group">
                                        <label class="text-left">Fineness</label>
                                        <input type="text" v-model="form.fineness" />
                                    </div>
                                    <div class="form-group">
                                        <label class="text-left">Hallmark</label>
                                        <input type="text" v-model="form.hallmark" />
                                    </div>
                                    <div class="form-group">
                                        <label class="text-left">Serial Number</label>
                                        <input type="text" v-model="form.serial_number" />
                                    </div>
                                    <div class="form-group">
                                        <label class="text-left">Year</label>
                                        <input type="text" v-model="form.year" />
                                    </div>
                                </form>
                            </div>
                            <div class="d-flex justify-content-end mt-5">
                                <b-button class="mr-2" variant="outline-danger" @click="hideModal">Cancel</b-button>
                                <b-button class="ml-2" variant="success" @click="submitMetal">
                                    <span v-show="!loadingMetal">Submit</span>
                                    <b-spinner label="Loading..." variant="light" small v-show="loadingMetal"></b-spinner>
                                </b-button>
                            </div>
                        </b-modal>

                        <!-- ======== Modal Operator ========= -->
                        <b-modal ref="modal-operator" hide-footer title="Konfirmation Metal">
                            <div class="d-block text-center">
                                <h3 v-show="status_operator == 1">Are You Sure to Accept this Metal?</h3>
                                <h3 v-show="status_operator == 2">Are You Sure to Reject this Metal?</h3>
                            </div>
                            <div class="d-flex justify-content-end mt-5">
                                <b-button class="mr-2" variant="outline-danger" @click="hideModalOperator">Cancel</b-button>
                                <b-button class="ml-2" variant="success" @click="operatorKonfirmation">
                                    <span v-show="!loadingOperatorConfirm">Submit</span>
                                    <b-spinner label="Loading..." variant="light" small v-show="loadingOperatorConfirm"></b-spinner>
                                </b-button>
                            </div>
                        </b-modal>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
// import axios from "axios";
export default {
    components: {},
    data() {
        return {
            dataTransaction: [],
            loadingOperatorConfirm: false,
            loadingWithdrawal: false,
            loadingKbi: false,
            loadingMetal: false,
            showDetailholding: false,
            dataMetal: [],
            dataHolding: [],
            dataDetail: [],
            pageOfItems: [],
            currentPage: 1,
            perPage: 10,
            lengthData: 1,
            trade_id: [],
            metal_id: "",
            withdrawal_id: "",
            status_operator: "",
            offset: 0,
            withdrawal_status: "",
            typeWithdraw: "",
            role: "",
            data: [],
            form: {
                allocated: "",
                hub: "",
                dimension: "",
                fineness: "",
                hallmark: "",
                serial_number: "",
                year: "",
            },
            form_validation: {
                allocated: null,
                hub: "",
                dimension: "",
                fineness: "",
                hallmark: "",
                serial_number: "",
                year: "",
            },
        };
    },
    mounted() {
        // this.getData(this.currentPage);
        this.withdrawal_id = this.$route.params.id;
        this.role = sessionStorage.getItem("role");

        let url;
        if (this.role == 5) {
            url = "/v1/clearing/operator/get/detail/" + this.withdrawal_id;
        } else if (this.role == 2) {
            url = "/v1/clearing/admin/abx/get/detail/" + this.withdrawal_id;
        } else if (this.role == 1) {
            url = "/v2/clearing/admin/get/detail/withdrawal/" + this.withdrawal_id;
        }
        axios.get(url).then((res) => {
            this.dataMetal = res.data.data.body.metal_info;
            this.dataDetail = res.data.data.body;
            this.typeWithdraw = res.data.data.body.type;
            this.withdrawal_status = res.data.data.body.status;
            for (let i = 0; i < this.dataMetal.length; i++) {
                this.form.allocated = this.dataMetal[i].allocated;
                this.form.hub = this.dataMetal[i].hub;
                this.form.dimension = this.dataMetal[i].dimension;
                this.form.fineness = this.dataMetal[i].fineness;
                this.form.hallmark = this.dataMetal[i].hallmark;
                this.form.serial_number = this.dataMetal[i].serial_number;
                this.form.year = this.dataMetal[i].year;
            }
        });
    },
    methods: {
        showModalOperator(id, status) {
            this.status_operator = status;
            this.metal_id = id;
            this.$refs["modal-operator"].show();
        },

        hideModalOperator() {
            this.$refs["modal-operator"].hide();
        },
        showModal(id) {
            this.metal_id = id;
            this.$refs["my-modal"].show();
        },
        hideModal() {
            this.$refs["my-modal"].hide();
        },

        operatorKonfirmation() {
            this.loadingOperatorConfirm = true;
            let value;
            if (this.status_operator == 1) {
                value = "accept";
            } else {
                value = "reject";
            }
            axios
                .put("/v1/clearing/operator/metal/confirmation/" + this.metal_id, { option: value })
                .then((res) => {
                    if (res.status == 200) {
                        this.loadingOperatorConfirm = false;
                        this.$toast.success(res.data.message, {
                            position: "top-center",
                            timeout: 3000,
                            closeOnClick: true,
                            pauseOnFocusLoss: false,
                            pauseOnHover: false,
                            showCloseButtonOnHover: false,
                            hideProgressBar: false,
                            closeButton: "button",
                            icon: true,
                            rtl: false,
                        });
                        setTimeout(function () {
                            location.reload();
                        }, 3000);
                    }
                })
                .catch((err) => {
                    this.loadingOperatorConfirm = false;
                    this.$toast.error(err.response.data.message, {
                        position: "top-center",
                        timeout: 3000,
                        closeOnClick: true,
                        pauseOnFocusLoss: false,
                        pauseOnHover: false,
                        showCloseButtonOnHover: false,
                        hideProgressBar: false,
                        closeButton: "button",
                        icon: true,
                        rtl: false,
                    });
                });
        },
        submitMetal() {
            this.loadingMetal = true;
            axios
                .put("/v1/clearing/admin/abx/metal/" + this.metal_id, this.form)
                .then((res) => {
                    if (res.status == 200) {
                        this.loadingMetal = false;
                        this.$toast.success(res.data.message, {
                            position: "top-center",
                            timeout: 3000,
                            closeOnClick: true,
                            pauseOnFocusLoss: false,
                            pauseOnHover: false,
                            showCloseButtonOnHover: false,
                            hideProgressBar: false,
                            closeButton: "button",
                            icon: true,
                            rtl: false,
                        });
                        setTimeout(function () {
                            location.reload();
                        }, 3000);
                    }
                })
                .catch((error) => {
                    this.loadingMetal = false;
                    this.$toast.error(error.response.data.message, {
                        position: "top-center",
                        timeout: 3000,
                        closeOnClick: true,
                        pauseOnFocusLoss: false,
                        pauseOnHover: false,
                        showCloseButtonOnHover: false,
                        hideProgressBar: false,
                        closeButton: "button",
                        icon: true,
                        rtl: false,
                    });
                });
        },
        processWithdrawl() {
            this.loadingWithdrawal = true;
            this.$store.dispatch("getLocation").then((resGeo) => {
                let info;
                if (resGeo.cityData[0]) {
                    info = JSON.stringify(resGeo.cityData[0]);
                } else {
                    let data = resGeo;
                    data.newLatLong = resGeo.cityLatLong.split(",");
                    info = JSON.stringify(data);
                }
                axios
                    .put("/v2/clearing/admin/withdrawal/confirmation", { _id: this.withdrawal_id, info: info })
                    .then((res) => {
                        if (res.status == 200) {
                            this.loadingWithdrawal = false;
                            this.$toast.success(res.data.message, {
                                position: "top-center",
                                timeout: 3000,
                                closeOnClick: true,
                                pauseOnFocusLoss: false,
                                pauseOnHover: false,
                                showCloseButtonOnHover: false,
                                hideProgressBar: false,
                                closeButton: "button",
                                icon: true,
                                rtl: false,
                            });
                            setTimeout(function () {
                                location.reload();
                            }, 3000);
                        }
                    })
                    .catch((err) => {
                        this.loadingWithdrawal = false;
                        this.$toast.error(err.response.data.message, {
                            position: "top-center",
                            timeout: 3000,
                            closeOnClick: true,
                            pauseOnFocusLoss: false,
                            pauseOnHover: false,
                            showCloseButtonOnHover: false,
                            hideProgressBar: false,
                            closeButton: "button",
                            icon: true,
                            rtl: false,
                        });
                    });
            });
        },
        apprveKBI() {
            this.loadingKbi = true;
            this.$store.dispatch("getLocation").then((resGeo) => {
                let info;
                if (resGeo.cityData[0]) {
                    info = JSON.stringify(resGeo.cityData[0]);
                } else {
                    let data = resGeo;
                    data.newLatLong = resGeo.cityLatLong.split(",");
                    info = JSON.stringify(data);
                }
                axios
                    .put("/v2/clearing/admin/delivery/confirmation", { _id: this.withdrawal_id, info: info })
                    .then((res) => {
                        if (res.status == 200) {
                            this.loadingKbi = false;
                            this.$toast.success(res.data.message, {
                                position: "top-center",
                                timeout: 3000,
                                closeOnClick: true,
                                pauseOnFocusLoss: false,
                                pauseOnHover: false,
                                showCloseButtonOnHover: false,
                                hideProgressBar: false,
                                closeButton: "button",
                                icon: true,
                                rtl: false,
                            });
                            setTimeout(function () {
                                location.reload();
                            }, 3000);
                        }
                    })
                    .catch((err) => {
                        this.loadingKbi = false;
                        this.$toast.error(err.response.data.message, {
                            position: "top-center",
                            timeout: 3000,
                            closeOnClick: true,
                            pauseOnFocusLoss: false,
                            pauseOnHover: false,
                            showCloseButtonOnHover: false,
                            hideProgressBar: false,
                            closeButton: "button",
                            icon: true,
                            rtl: false,
                        });
                    });
            });
        },

        offExchangeApprove() {
            this.loadingKbi = true;
            this.$store.dispatch("getLocation").then((resGeo) => {
                let info;
                if (resGeo.cityData[0]) {
                    info = JSON.stringify(resGeo.cityData[0]);
                } else {
                    let data = resGeo;
                    data.newLatLong = resGeo.cityLatLong.split(",");
                    info = JSON.stringify(data);
                }
                axios
                    .put("/v2/clearing/admin/offexchange/withdrawal/update", { _id: this.withdrawal_id, info: info })
                    .then((res) => {
                        if (res.status == 200) {
                            this.loadingKbi = false;
                            this.$toast.success(res.data.message);
                            setTimeout(function () {
                                location.reload();
                            }, 3000);
                        }
                    })
                    .catch((err) => {
                        this.loadingKbi = false;
                        this.$toast.error(err.response.data.message);
                    });
            });
        },
        offExchangeConfirmation() {
            this.loadingKbi = true;
            this.$store.dispatch("getLocation").then((resGeo) => {
                let info;
                if (resGeo.cityData[0]) {
                    info = JSON.stringify(resGeo.cityData[0]);
                } else {
                    let data = resGeo;
                    data.newLatLong = resGeo.cityLatLong.split(",");
                    info = JSON.stringify(data);
                }
                axios
                    .put("/v2/clearing/admin/offexchange/withdrawal/confirmation", { _id: this.withdrawal_id, info: info })
                    .then((res) => {
                        if (res.status == 200) {
                            this.loadingKbi = false;
                            this.$toast.success(res.data.message);
                            setTimeout(function () {
                                location.reload();
                            }, 3000);
                        }
                    })
                    .catch((err) => {
                        this.loadingKbi = false;
                        this.$toast.error(err.response.data.message);
                    });
            });
        },
    },
    computed: {
        rows() {
            return (this.currentPage - 1) * this.perPage;
        },
        getGeoData() {
            let data = this.$store.state.geoData;
            return data;
        },
    },
};
</script>

<style scoped>
/*.wrap-dashboard {
  min-height: 100vh;
  margin: 0;
  display: grid;
  grid-template-rows: auto 1fr auto;
}*/

.fa-check {
    color: #28a745;
    cursor: pointer;
}
.fa-times {
    color: #dc3545;
    cursor: pointer;
}

.title-page {
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 40px;
}

.label-input {
    width: 5rem;
}

.select-input,
input {
    width: 100%;
}

.form-group {
    display: flex;
}

.select-input,
input {
    border-radius: 5px;
    border: 1px solid #454545;
    padding: 0 10px;
    font-size: 13px;
}

.buuton-add {
    text-align: right;
    font-size: 13px;
}

.button-more {
    cursor: pointer;
    margin-bottom: 0.4rem;
    display: flex;
    align-items: center;
    gap: 1.3rem;
    font-weight: bold;
}

.icons {
    transition: all 0.3s ease-out;
}

.rotateIcons {
    transform: rotate(180deg);
}

/* .wrap-content {
  width: 98%;
  height: 100%;
  margin: 0 auto;
  padding: 20px 25px;
} */

.content {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    justify-content: flex-end;
}

.icon-trade {
    width: 25px;
}

.footer {
    background: var(--primary-color);
    color: white;
    padding: 20px 47px;
    display: flex;
    justify-content: space-between;
    margin-top: auto;
    height: 62px;
}

.filter {
    display: table-cell;
    width: 40%;
    font-size: 12px;
}
.wrap-input {
    display: flex;
    gap: 0.5rem;
}

select {
    width: 130px;
    border-radius: 3px;
    border-color: #a5a5a5a5;
    padding: 3px;
}

.card {
    width: 314px;
    font-size: 14px;
    border-radius: 10px;
}

.card-header {
    padding: 1rem;
}

.buyer,
.seller,
.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.title {
    font-size: 12px;
    position: relative;
    left: 20px;
}

.title::before {
    content: "";
    position: absolute;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: var(--scondary-color);
    left: -17px;
    top: 3px;
}

.buyer {
    margin-bottom: 20px;
}

.content-right {
    text-align: right;
}

.amount {
    font-weight: bold;
}

.btn-primer {
    background: var(--scondary-color);
    border: none;
    color: #ffffff;
    padding: 5px 25px;
    border-radius: 5px;
}

.btn-primer:hover {
    background-color: var(--scondary-color-hover);
}

.status-allocated {
    font-weight: 600;
    color: #2dce89;
}

.status-processed {
    font-weight: 600;
    color: rgb(172, 143, 26);
}

.status-rejected {
    font-weight: 600;
    color: #f5365c;
}

.request {
    /* background: rgba(252, 108, 4, 0.1); */
    font-weight: 600;
    color: rgb(252, 108, 4);
    border-radius: 5px;
}
.abx {
    /* background: rgba(172, 143, 26, 0.1); */
    font-weight: 600;
    color: rgb(172, 143, 26);
    border-radius: 5px;
}
.operator {
    /* background: rgba(89, 37, 25, 0.1); */
    font-weight: 600;
    color: rgb(89, 37, 25);
    border-radius: 5px;
}
.kbi {
    /* background: rgba(60, 60, 92, 0.1); */
    font-weight: 600;
    color: rgb(60, 60, 92);
    border-radius: 5px;
}
.shipping {
    /* background: rgba(188, 180, 179, 0.1); */
    font-weight: 600;
    color: rgb(54, 255, 14);
    border-radius: 5px;
}
.handover {
    /* background: rgba(50, 90, 115, 0.1); */
    font-weight: 600;
    color: rgb(50, 90, 115);
    border-radius: 5px;
}
.kprk {
    /* background: rgba(252, 108, 4, 0.1); */
    font-weight: 600;
    color: rgb(252, 108, 4);
    border-radius: 5px;
}
.nasabah {
    /* background: rgba(40, 167, 69, 0.1); */
    font-weight: 600;
    color: rgb(40, 167, 69);
    border-radius: 5px;
}
.decline {
    /* background: rgba(220, 53, 69, 0.1); */
    font-weight: 600;
    color: rgb(220, 53, 69);
    border-radius: 5px;
}
</style>
